import { isIdCardNo, isPhone, isEmail, isNumber } from "@/libs/checker.js";

// 手机号验证(必填加正确性)
export const validatePhone = (rule, value, callback) => {
    if (value) {
        if (isPhone(value)) {
            callback()
        } else {
            callback(new Error('请输入正确的手机号'));
        }
    } else {
        callback(new Error('请输入手机号'));
    }
}

// 邮箱验证(必填加正确性)
export const validateEmail = (rule, value, callback) => {
    if (value) {
        if (isEmail(value)) {
            callback()
        } else {
            callback(new Error('请输入正确的邮箱地址'));
        }
    } else {
        callback(new Error('请输入邮箱'))
    }

}

// 身份证验证(必填加正确性)
export const validateCardId = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('请输入身份证号'));
    }
    if (isIdCardNo(value)) {
        callback()
    } else {
        callback(new Error('请输入正确的身份证号'));
    }
}

// 身份证验证（仅正确性）
export const validateCardIdCorrect = (rule, value, callback) => {
    if (value) {
        if (isIdCardNo(value)) {
            callback()
        } else {
            callback(new Error('请输入正确的身份证号'));
        }
    } else {
        callback()
    }
}

// 邮箱验证（仅正确性）
export const validateEmailCorrect = (rule, value, callback) => {
    if (value) {
        if (isEmail(value)) {
            callback()
        } else {
            callback(new Error('请输入正确的邮箱地址'));
        }
    } else {
        callback()
    }

}

// 手机号验证（仅正确性）
export const validatePhoneCorrect = (rule, value, callback) => {
    if (value) {
        if (isPhone(value)) {
            callback()
        } else {
            callback(new Error('请输入正确的手机号'));
        }
    } else {
        callback()
    }

}

// 验证金额(必填加正确性)
export const validateMoney = (rule, value, callback) => {
    if (value) {
        if (isNumber(value)) {
            callback()
        } else {
            callback(new Error('请输入正确的数字金额'));
        }
    } else {
        callback(new Error('请输入数字金额'));
    }
}

// 验证金额（仅正确性）
export const validateMoneyCorrect = (rule, value, callback) => {
    if (value) {
        if (isNumber(value)) {
            callback()
        } else {
            callback(new Error('请输入数字金额'));
        }
    } else {
        callback()
    }
}

// 验证数字（军训服衣服、鞋子尺码等的验证）
export const validateNumber = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('请输入'));
    }
    if (isNumber(value)) {
        callback()
    } else {
        callback(new Error('请输入正确的数字'));
    }
}

// 根据身份证获取生日
export const getBirthdayByIdNO = (IdNO) => {
    let birthday = "";
    if (IdNO.length == 18) {
        birthday = IdNO.substr(6, 8);
        return birthday.replace(/(.{4})(.{2})/, "$1-$2-");
    } else if (IdNO.length == 15) {
        birthday = "19" + IdNO.substr(6, 6);
        return birthday.replace(/(.{4})(.{2})/, "$1-$2-");
    } else {
        return "";
    }
}
// 获取性别，male 男 female 女
export const getSexByIdNO = (IdNO) => {
    return IdNO.charAt(IdNO.length - 2) % 2 === 0 ? 1 : 0;
}
