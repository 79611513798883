<template>
  <div id="login_page" class="login-page">
    <div class="login-bg">
      <div class="login-bg-shadow">
        <div class="login-bg-shadow-mask"></div>
      </div>
      <img src="../../assets/images/logo_login.png"/>
    </div>

    <div class="login-main">
      <div class="login-container" id="login_box" v-loading="isLoading">
        <div class="login-title">
          <!-- <img src="../../assets/images/icon/icon_user.png" alt="" /> -->
          <span class="title">密码登陆</span>
        </div>
        <div class="login-content">
          <el-form :model="data" :rules="rules" ref="data" label-position="left">
            <el-form-item prop="phoneNumber">
              <el-input
                v-model="data.phoneNumber"
                type="text"
                placeholder="请输入手机号"
                @keyup.enter.native="submitForm()"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="data.password"
                placeholder="密码"
                type="password"
                show-password
                @keyup.enter.native="submitForm()"
              ></el-input>
            </el-form-item>
            <div class="other-container">
              <div class="to-register" @click="$router.push('/register')">
                还没账号？
              </div>
              <div class="to-pwd" @click="$router.push('/resetPwd')">
                忘记密码?
              </div>
            </div>
            <el-button
              type="primary"
              :disabled="isSubmit"
              class="btn btn-success btn-block"
              @click="submitForm()"
              >登录</el-button
            >
            <div class="login-footer">
              <img src="../../assets/images/icon/icon_weixin@2x.png" alt="" />
              <span @click="handleShowEwm">使用微信登录</span>
            </div>
          </el-form>
        </div>
        <div v-if="sysMsg" class="err-msg">{{ sysMsg }}</div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      custom-class="wx-login-dialog"
      center
    >
      <div class="wx-login-container">
        <img src="../../assets/images/logo.png" alt="" />
        <!-- <div class="title">数字化资源云平台</div> -->
        <div class="sub-title">欢迎来到属于您的设计空间</div>
        <div class="qr-code-box">
          <canvas id="canvas" class="code"></canvas>
        </div>
        <div class="wx-footer">
          <img src="../../assets/images/icon/icon_weixin@2x.png" alt="" />
          <span>使用微信登录</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { validatePhone } from "@/libs/elementChecker.js";
import { hex_sha1 } from "@/libs/sha1.js";
export default {
  name: "login",
  data() {
    return {
      isSubmit: false,
      data: {
        phoneNumber: "",
        password: "",
      },
      rules: {
        phoneNumber: [
          {
            required: true,
            trigger: "blur",
            validator: validatePhone,
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      sysMsg: "",
      SUCCESS_CODE: 0,
      isLoading: false,
      dialogVisible: false,
    };
  },
  mounted() {},
  methods: {
    // 展示微信二维码
    handleShowEwm() {
      this.dialogVisible = true;
      let url = "https://wx.qq.com/";
      this.$nextTick(() => {
        var canvas = document.getElementById("canvas");
        QRCode.toCanvas(canvas, url, { scale: 6 }, function (error) {
          if (error) console.error(error);
          console.log("success!");
        });
      });
    },
    // 账号密码登录
    goLogin() {
      this.isSubmit = true;
      let params = {
        username: this.data.phoneNumber,
        // password: this.data.password,
        password: hex_sha1(this.data.password),
      };
      // this.$router.push("/home");
      this.$http
        .post("/sys-user/login2", params)
        .then((res) => {
          console.log(res);
          if (res.code == this.SUCCESS_CODE) {
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            localStorage.setItem("token", res.data.token);
            localStorage.setItem(
              "nickname",
              res.data.nickname ? res.data.nickname : res.data.username
            );
            // 跳转到首页
            this.$router.push("/home");
          } else {
            this.$message.error(`${res.msg}`);
          }
          this.isSubmit = false;
          this.isLoading = false;
        })
        .catch((err) => {
          this.$message.error(`登录失败，${err}`);
          this.isSubmit = false;
          this.isLoading = false;
        });
    },
    // 提交登录表单--登录
    submitForm() {
      this.$refs["data"].validate((valid) => {
        if (valid) {
          this.goLogin();
        } else {
          return false;
        }
      });
    },
  },
};
</script>
